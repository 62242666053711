import moment from 'moment';

function getDeliveryCompanyLabel (deliveryCompany) {
  let deliveryCompanyLabel = '';

  switch (deliveryCompany) {
    case 'CJ':
      deliveryCompanyLabel = 'CJ대한통운';
      break;
    case 'LOGEN':
      deliveryCompanyLabel = '로젠택배';
      break;
    case 'LOTTE':
      deliveryCompanyLabel = '롯데택배';
      break;
    case 'EPOST':
      deliveryCompanyLabel = '우체국';
      break;
    case 'HANJIN':
      deliveryCompanyLabel = '한진택배';
      break;
    case 'ETC':
      deliveryCompanyLabel = '기타';
      break;
    default:
      deliveryCompanyLabel = '';
  }

  return deliveryCompanyLabel;
}

function getLanguageKey(type, value) {
  value = !!value ? value.toLowerCase().replace(/_/g, '-') : '';
  return value ? `${type}.${value}` : '';
}

function addCommas(num) {
  if (!num) return num;

  const [wholeNum, decimalNum] = num.toString().split('.');
  if (parseInt(wholeNum) < 1000) return num;

  return wholeNum.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (decimalNum ? `.${decimalNum}` : '');
}

function roundToFive(str) {
  const num = parseFloat(str);
  if (isNaN(num)) {
    return NaN;
  }
  return +(Math.round(num + "e+5") + "e-5");
}

function dateFormat(date) {
  return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '';
}

function currentDateFormat() {
  return moment().format('YYYY-MM-DD HH:mm:ss')
}

const numberValidator = (label) => ({
  validator(_, value) {
    if (!parseInt(value)) {
      return Promise.reject(new Error(`${label} must be a number!`));
    }
    if (value < 0) {
      return Promise.reject(new Error(`${label} must be greater than zero!`));
    }
    return Promise.resolve();
  },
});

export {
    getDeliveryCompanyLabel,
    addCommas,
    roundToFive,
    dateFormat,
    currentDateFormat,
    numberValidator,
    getLanguageKey
}