import React, { useEffect, useState } from 'react';

import RangePicker from '../../common/RangePicker';
import { useTranslation } from 'react-i18next';

import { Col, Row, Radio, Input, Space, Button } from 'antd';

const { Search } = Input;

export default function OrderSearch({ setDatePeriod, searchOrder, setOrderStatus }) {
    const [listType, setListType] = useState(false);
    const { t } = useTranslation();

    const filterList = async (type) => {
        setListType(type);
    };

    const onRangeChange = (dates, dateStrings) => {
        if (dates) {
            setDatePeriod(dateStrings);
        } else {
            setDatePeriod([]);
        }
    };

    return (
        <>
            <Row style={{ marginBottom: 10 }}>
                <Col span={24} style={{ marginBottom: 20 }}>
                    <Radio.Group
                        value={listType || 'keep'}
                        defaultValue="keep"
                        style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
                        onChange={(e) => filterList(e.target.value)}
                    >
                        <Radio.Button
                            value="keep"
                            className="primary-color"
                            style={{ flex: 1, textAlign: 'center' }}
                            onClick={() => setOrderStatus('')}
                        >
                            { t('common.all') }
                        </Radio.Button>
                        <Radio.Button
                            value="discard"
                            style={{ flex: 1, textAlign: 'center' }}
                            onClick={() => setOrderStatus('WAITING_PAYMENT')}
                        >
                            { t('orders.before-payment') }
                        </Radio.Button>
                        <Radio.Button
                            value="done"
                            style={{ flex: 1, textAlign: 'center' }}
                            onClick={() => setOrderStatus('ORDERED')}
                        >
                            { t('orders.payment-completed') }
                        </Radio.Button>
                    </Radio.Group>
                </Col>
                <Col span={24} style={{ width: "100%" }}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Space.Compact style={{ width: "100%" }}>
                            <RangePicker onRangeChange={onRangeChange} />
                            <Search placeholder={`${ t('common.search') }`}
                                    onSearch={searchOrder}
                            />
                        </Space.Compact>
                    </Space>
                </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
                <Col>
                    <Space direction="horizontal">
                        <Button>{ t('orders.refund-settlement') }</Button>
                        <Button>{ t('common.download') }</Button>
                    </Space>
                </Col>
            </Row>
        </>
    )
}