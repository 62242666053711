import React from 'react';

import { useTranslation } from 'react-i18next';
import { getLanguageKey } from '../common/Util';
import moment from 'moment';

export default function Columns () {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('common.number'),
      dataIndex: 'id',
    },
    {
      title: t('products.product-name'),
      dataIndex: 'product',
      render: (val) => <span>{ val.productName }</span>, 
    },
    {
      title: t('common.nickname'),
      dataIndex: 'ownerName',
    },
    {
      title: t('orders.open-date'),
      dataIndex: 'openTime',
      render: (val) => <span>{ moment(val).format("YYYY-MM-DD HH:mm:ss") }</span>, 
    },
    {
      title: t('common.status'),
      dataIndex: 'status',
      render: (val) => <span>{ t(getLanguageKey('orders.detail', val)) }</span>,
    },
  ];

  return columns;
};
