import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Col, Row } from 'antd';

import OrderInfo from '../component/orders/detail/OrderInfo';
import OrderItem from '../component/orders/detail/OrderItem';

import axios from 'axios';

export default function OrderDetail() {
    const [orderDetailData, setOrderDetailData] = useState();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const getOrderDetail = async (id) => {
        try {
          const { data } = await axios.get(`/orders/${id}`);
          const orderItems = data.orderItems.map((item) => ({ ...item, key: item.id }));

          setOrderDetailData({ ...data, orderItems });
        } catch (error) {
          console.error(error.message);
        }
    };

    useEffect(() => {
        getOrderDetail(searchParams.get('id'));
    }, []);

    return (
        <>
            <Row gutter={16} style={{ marginBottom: 20 }}>
                <Col span={8}>
                    { orderDetailData && <OrderInfo detail={orderDetailData} /> }
                </Col>
                <Col span={16}>
                    { orderDetailData && <OrderItem orderItems={orderDetailData.orderItems} /> }
                </Col>
            </Row>
            {/* <Row>
                <Col span={12}>주문 포인트</Col>
            </Row> */}
        </>
    )
}