import React from 'react';
import { useTranslation } from 'react-i18next';

import { addCommas, getDeliveryCompanyLabel } from '../../common/Util';

export default function Columns () {
  const { t } = useTranslation();

  const columns = [ 
    { 
      title: t('deliveries.number'),
      dataIndex: 'id', 
    }, 
    { 
      title: t('deliveries.products-name'),
      dataIndex: 'product', 
      render: (val) => <span>{ val.productName }</span>, 
    },
    { 
      title: t('deliveries.detail.delivery-company'),
      dataIndex: 'deliveryCompany'
    },
    { 
      title: t('deliveries.detail.invoice-number'),
      dataIndex: 'invoiceNumber', 
    }, 
    { 
      title: t('deliveries.detail.retail-price'),
      dataIndex: 'product',
      render: (val) => <span>{ addCommas(val.retailPrice) }</span>, 
    }, 
    { 
      title: t('deliveries.detail.quantity'),
      dataIndex: 'qty', 
    },
  ]; 
 
  return columns; 
};
