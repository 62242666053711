import React, { useState } from "react";
import axios from "axios";

import SimulationForm from './SimulationForm'
import SimulationList from './SimulationList'

export default function Simulation() {
  const [simulationData, setSimulationData] = useState(null);

  const getSimulationData = async (params) => {
    const { data } = await axios.get('/admin/product-simulation', { params });
    const productSimulations = data.productSimulations.map((data, idx) => {
      return { ...data, key: idx };
    });
    setSimulationData({ ...data, productSimulations });
  }

  return (
    <>
      <SimulationForm getSimulationData={getSimulationData} />
      <SimulationList simulationData={simulationData} />
    </>
  )
}