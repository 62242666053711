import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { dateFormat } from '../../common/Util';

export default function Columns () {
  const { t } = useTranslation();
  const [ id, setId ] = useState();

  const columns = [
    {
      title: t('coupon.code'),
      dataIndex: 'code',
      render: (val, record) => (
        <>
          <Link to={`/coupon-detail?id=${record.id}`}>{ val }</Link>
        </>
      ),
      sorter: true,
    },
    {
      title: t('coupon.point'),
      dataIndex: 'points',
      sorter: true,
    },
    {
      title: t('coupon.box'),
      dataIndex: 'boxCode',
      sorter: true,
    },
    {
      title: t('coupon.description'),
      dataIndex: 'description',
    },
    {
      title: t('coupon.issued-count'),
      dataIndex: 'totalIssued',
    },
    {
      title: t('coupon.used-count'),
      dataIndex: 'usedCount',
    },
    {
      title: t('coupon.start-date'),
      dataIndex: 'validFrom',
      render: val => (
        <>
          { dateFormat(val) }
        </>
      ),
      sorter: true,
    },
    {
      title: t('coupon.end-date'),
      dataIndex: 'validTo',
      render: val => (
        <>
          { dateFormat(val) }
        </>
      ),
      sorter: true,
    },
  ];

  return columns;
};
