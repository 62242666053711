import React, { useState } from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

import { currentDateFormat } from '../../common/Util';

import { UploadOutlined } from '@ant-design/icons';
import { Col, Row, Input, Button, message, Upload, Space, Radio } from 'antd';

const { Search } = Input;

export default function ProductSearch({ getProductList, isComplete, setIsComplete,selectedRows, onSearchKeyword, listType, setListType, filterList }) {

  const [fileList, setFileList] = useState([]);

  const { t } = useTranslation();

  const handleUploadChange = async ({ file, fileList }) => {
    if (file.status !== 'uploading') {
      const formData = new FormData();
      formData.append('file', fileList[0].originFileObj);

      try {
        const token = Cookies.get('Authorization');
        const response = await axios.post('/products/import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.status === 201) {
          message.success(response.data);
        }
      } catch (error) {
        message.error('An error occurred during the upload.');
      }
    }

    return false; // Stop the upload process
  };

  const handleDownload = async () => {
    const response = await axios.get('/products/export', {
      method: 'GET',
      responseType: 'blob',
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `${ currentDateFormat() } 기준 제품리스트.xlsx`
    );
    document.body.appendChild(link);
    link.click();
  };

  const handleDelete = async () => {
    await Promise.all(selectedRows.map(async ({ key }) => {
      await axios.delete(`/products/${key}`);
    }));
    setIsComplete(!isComplete);
  };


  // const filterList = async (type) => {
  //   await getProductList(type === 'false' ? false : true, 1);
  //   setListType(type);
  // };



  return (
    <>
      <Row style={{ marginBottom: 10 }}>
        <Col span={12}>
          <Search placeholder={t('common.search')} onSearch={onSearchKeyword} />
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col span={12}>
          <Row gutter={16}>
            <Col>
              <Button onClick={() => { handleDelete() }}>{t('common.delete-selection')}</Button>
            </Col>
            <Col>
              <Upload
                fileList={fileList}
                onChange={handleUploadChange}
                beforeUpload={() => false}  // prevent auto upload
              >
                <Button icon={<UploadOutlined />}>{t('common.upload')}</Button>
              </Upload>
            </Col>
            <Col>
              <Button onClick={handleDownload}>{t('common.download')}</Button>
            </Col>
            <Col>
              {/*<Button>{t('common.save-all')}</Button>*/}
              {/*{t('products.visible-products')}*/}
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row>
            <Col span={12}>
              <Radio.Group
                value={listType || 'false'}
                defaultValue="false"
                style={{ display: 'flex', width: '100%', textAlign: 'center' }}
                onChange={(e) => filterList(e.target.value)}
              >
                <Radio.Button
                  value="false"
                  className="primary-color"
                  style={{ width: '50%' }}
                >
                  {t('common.all')}
                </Radio.Button>
                <Radio.Button
                  value="true"
                  style={{ width: '50%' }}
                >
                  {t('common.discard')}
                </Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
