import React, { useState, useEffect } from 'react';
import { Card, Typography, Input, Form, Button, Select } from 'antd';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import axios from 'axios';

import { getLanguageKey } from '../../common/Util';

const { Text } = Typography;
const { Option } = Select;

export default function Answer({ id, inquiryData }) {
    const [status, setStatus] = useState();
    const [inquiryStatuses, setInquiryStatuses] = useState();

    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        getInquryStatuses();
        setStatus(inquiryData.status);
    }, []);

    const getInquryStatuses = async () => {
        const { data } = await axios.get(`/inquiries/statuses`);
        setInquiryStatuses(data);
    }

    const mb20 = {
        marginBottom: '20px'
    };

    const handleStatusChange = (value) => {
        setStatus(value);
    };

    const onFinish = async (values) => {
        await axios.put(`/inquiries/${id}`, {
            answerTitle: values.answerTitle ? values.answerTitle : inquiryData.answerTitle,
            answerContents: values.answerContents ? values.answerContents : inquiryData.answerContents,
            status
        })
        .then(() => {
            alert(t('common.message.done'));
            navigate('/inquiry');
        })
        .catch(e => console.log(e));
    };
  
    return (
        <>
            <Form
                name="inquiry-form"
                onFinish={onFinish}
                initialValues={{ ...inquiryData }}
            >
                <Card>
                    <div style={mb20}>
                        <Text type="secondary">{t('inquiries.answer-title')}</Text>
                        <Form.Item name="answerTitle">
                            <Input placeholder={t('inquiries.answer-title')}/>
                        </Form.Item>
                    </div>
                    <div style={mb20}>
                        <Text type="secondary">{t('inquiries.answer-contents')}</Text>
                        <Form.Item name="answerContents">
                            <Input.TextArea rows={10} placeholder={t('inquiries.answer-contents')} />
                        </Form.Item>
                    </div>
                    <div style={mb20}>
                        <Text type="secondary">{t('inquiries.status')}</Text>
                        <Select value={status} style={{ width: '100%' }} onChange={handleStatusChange}>
                            {inquiryStatuses && inquiryStatuses.map((inquiryStatus) => (
                                <Option key={inquiryStatus.enumValue} value={inquiryStatus.enumValue}>
                                    { t(getLanguageKey('inquiries', inquiryStatus.enumValue)) }
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div>
                        <Button type="primary" block htmlType="submit">
                            { t("common.save") }
                        </Button>
                    </div>
                </Card>
            </Form>
        </>
    );
  };