import React, { useReducer, useEffect } from 'react';
import axios from 'axios';
import { Form, Input, Button, Checkbox, message } from 'antd';

const initialState = {
    id: null,
    duration: null,
    standardPrice: null,
    startPrice: null,
    endPrice: null,
    fakeUserPrice: null,
    blackUserPrice: null,
    isPriority: null
};

function reducer(state, action) {
    switch (action.type) {
        case 'SET_TOTAL_RULE':
            return { ...state, ...action.payload };
        default:
            throw new Error();
    }
}

export default function TotalRulePage() {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        axios.get('/total-rule')
            .then(response => {
                dispatch({ type: 'SET_TOTAL_RULE', payload: response.data });
                message.success('Data loaded successfully');
            })
            .catch(error => {
                message.error('Error loading data');
            });
    }, []);

    const handleSubmit = () => {
        axios.put('/total-rule', state)
            .then(response => {
                message.success('Data updated successfully');
            })
            .catch(error => {
                message.error('Error updating data');
            });
    };

    const handleInputChange = (e) => {
        dispatch({ type: 'SET_TOTAL_RULE', payload: { [e.target.name]: e.target.value } });
    };

    const handleCheckboxChange = (e) => {
        dispatch({ type: 'SET_TOTAL_RULE', payload: { [e.target.name]: e.target.checked } });
    };

    return (
        <Form onFinish={handleSubmit} style={{ margin: '0 auto', maxWidth: '600px' }}>
            <Form.Item label="Duration" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <Input name="duration" value={state.duration} onChange={handleInputChange} />
            </Form.Item>
            <Form.Item label="Standard Price" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <Input name="standardPrice" value={state.standardPrice} onChange={handleInputChange} />
            </Form.Item>
            <Form.Item label="Start Price" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <Input name="startPrice" value={state.startPrice} onChange={handleInputChange} />
            </Form.Item>
            <Form.Item label="End Price" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <Input name="endPrice" value={state.endPrice} onChange={handleInputChange} />
            </Form.Item>
            <Form.Item label="Fake User Price" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <Input name="fakeUserPrice" value={state.fakeUserPrice} onChange={handleInputChange} />
            </Form.Item>
            <Form.Item label="Black User Price" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <Input name="blackUserPrice" value={state.blackUserPrice} onChange={handleInputChange} />
            </Form.Item>
            <Form.Item label="Is Priority" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <Checkbox name="isPriority" checked={state.isPriority} onChange={handleCheckboxChange} />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
}