import React from 'react';
import { Popconfirm, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

import { addCommas, dateFormat } from '../../common/Util';

export default function Columns (handleDelete, handleSave, onChangeEvt) {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('common.operation'),
      dataIndex: 'operation',
      render: (_, record) => (
        <>
          <Popconfirm title={t("common.message.agree-delete")} onConfirm={() => handleDelete(record.key)}>
            <a style={{ marginRight: 10 }}>{t("common.discard")}</a>
          </Popconfirm>
          <Popconfirm title={t("common.message.agree-save")} onConfirm={() => handleSave(record.key)}>
            <a>{t("save")}</a>
          </Popconfirm>
        </>
      ),
    },
    {
      title: t('products.active'),
      dataIndex: 'isActive',
      render: (val, record) => <Checkbox defaultChecked={val} onChange={(e) => onChangeEvt(e, record, 'isActive')} />,
    },
    {
      title: t('products.visible'),
      dataIndex: 'isVisible',
      render: (val, record) => <Checkbox defaultChecked={val} onChange={(e) => onChangeEvt(e, record, 'isVisible')} />,
    },
    {
      title: t('products.boxcode'),
      dataIndex: 'boxCode',
      editable: true,
      sorter: true,
    },
    {
      title: t('products.product-code'),
      dataIndex: 'productCode',
      editable: true,
      sorter: true,
    },
    {
      title: t('products.product-name'),
      dataIndex: 'productName',
      editable: true,
      sorter: true,
    },
    {
      title: t('products.brand'),
      dataIndex: 'brand',
      editable: true,
      sorter: true,
    },
    {
      title: t('products.size'),
      dataIndex: 'size',
      editable: true,
    },
    {
      title: t('products.color'),
      dataIndex: 'color',
      editable: true,
    },
    {
      title: t('products.target-quantity'),
      dataIndex: 'targetQty',
      editable: true,
    },
    {
      title: t('products.real-quantity'),
      dataIndex: 'realQty',
      editable: true,
    },
    {
      title: t('products.shipping-guage'),
      dataIndex: 'shippingGuage',
      editable: true,
    },
    {
      title: t('products.supplier'),
      dataIndex: 'supplier',
      editable: true,
    },
    {
      title: t('products.main-category'),
      dataIndex: 'category',
      editable: true,
      sorter: true,
    },
    {
      title: t('products.sub-category'),
      dataIndex: 'subCategory',
      editable: true,
      sorter: true,
    },
    {
      title: t('products.storage'),
      dataIndex: 'storage',
      editable: true,
    },
    {
      title: t('products.purchase-price'),
      dataIndex: 'purchasePrice',
      render: (val) => <span>{ addCommas(val) }</span>,
      editable: true,
    },
    {
      title: t('products.retail-price'),
      dataIndex: 'retailPrice',
      render: (val) => <span>{ addCommas(val) }</span>,
      editable: true,
    },
    {
      title: t('products.main-image'),
      dataIndex: 'mainImageURL',
      render: (mainImageURL) => (
        <img src={`${mainImageURL}`} style={{width: '50px', height: '50px'}} />
      ),
      editable: false,
    },
    {
      title: t('products.sub-image1'),
      dataIndex: 'subImageURL1',
      render: (subImageURL1) => (
        <img src={`${subImageURL1}`} style={{width: '50px', height: '50px'}} />
      ),
      editable: false,
    },
    {
      title: t('products.product-detail'),
      dataIndex: 'productDetail',
      editable: true,
    },
    {
      title: t('products.features'),
      dataIndex: 'features',
      editable: true,
    },
    {
      title: t('products.after-service'),
      dataIndex: 'afterService',
      editable: true,
    },
    {
      title: t('common.update-datetime'),
      dataIndex: 'updateDateTime',
      render: (val) => <span>{ dateFormat(val) }</span>,
      editable: false,
      sorter: true,
    },
    {
      title: t('products.is-gift-unavailable'),
      dataIndex: 'isGiftUnAvailable',
      render: (val, record) => (
        <Checkbox defaultChecked={val} onChange={(e) => onChangeEvt(e, record, 'isGiftUnAvailable')} />
      ),
    },
    {
      title: t('products.is-mobile-coupon'),
      dataIndex: 'isMobileCoupon',
      render: (val, record) => (
        <Checkbox defaultChecked={val} onChange={(e) => onChangeEvt(e, record, 'isMobileCoupon')} />
      ),
    },
    {
      title: t('common.create-datetime'),
      dataIndex: 'createDateTime',
      render: (val) => <span>{ dateFormat(val) }</span>,
      editable: false,
      sorter: true,
    },
  ];

  return columns;
};
