import React from 'react';

import { Popconfirm, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

import { addCommas } from '../../../common/Util';

export default function Columns (handleDelete, handleSave, onChangeEvt) {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('common.operation'),
      dataIndex: 'operation',
      render: (_, record) => (
        <>
          {/* <Popconfirm title={t("common.message.agree-delete")} onConfirm={() => handleDelete(record.key)}>
            <a style={{ marginRight: 10 }}>{t("common.delete")}</a>
          </Popconfirm> */}
          <Popconfirm title={t("common.message.agree-save")} onConfirm={() => handleSave(record.key)}>
            <a>{t("save")}</a>
          </Popconfirm>
        </>
      ),
    },
    {
      title: t('settings.box.real-name'),
      dataIndex: 'boxRealName',
      editable: true,
    },
    {
      title: t('settings.box.code'),
      dataIndex: 'boxCode',
      editable: true,
    },
    {
      title: t('settings.box.price'),
      dataIndex: 'boxPrice',
      render: (val) => <span>{ addCommas(val) }</span>,
      editable: true,
    },
    {
      title: t('settings.box.icon-url'),
      dataIndex: 'boxImageURL',
      editable: true,
    },
    {
      title: t('settings.box.background-image-url'),
      dataIndex: 'boxBackgroundImageURL',
      editable: true,
    },
    {
      title: t('settings.box.active'),
      dataIndex: 'isActive',
      render: (val, record) => <Checkbox defaultChecked={val} onChange={(e) => onChangeEvt(e, record, 'isActive')} />,  
    },
    {
      title: t('settings.box.convert-a-box'),
      dataIndex: 'convertABox',
      editable: true,
    },
  ];

  return columns;
};
