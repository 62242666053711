import React, { useReducer, useEffect } from 'react';
import axios from 'axios';
import { Form, Input, Button, Table, message, Space } from 'antd';

const initialState = {
    terms: [],
};

function reducer(state, action) {
    switch (action.type) {
        case 'SET_TERMS':
            return { ...state, terms: action.payload };
        default:
            throw new Error();
    }
}

export default function TermsOfService() {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        axios.get('/terms')
            .then(response => {
                dispatch({ type: 'SET_TERMS', payload: response.data });
                message.success('Data loaded successfully');
            })
            .catch(error => {
                message.error('Error loading data');
            });
    }, []);

    const handleAdd = async () => {
        const newTerm = { title: '', content: '' };
        try {
            const response = await axios.post('/terms', newTerm);
            dispatch({ type: 'SET_TERMS', payload: [...state.terms, response.data] });
        } catch (error) {
            console.error('Error creating term:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`/terms/${id}`);
            dispatch({ type: 'SET_TERMS', payload: state.terms.filter(term => term.id !== id) });
            message.success('Term deleted successfully');
        } catch (error) {
            console.error(`Error deleting term with ID ${id}:`, error);
            message.error(`Error deleting term with ID ${id}`);
        }
    };

    const handleSaveAll = async () => {
        for (const term of state.terms) {
            try {
                await axios.put(`/terms/${term.id}`, term);
            } catch (error) {
                console.error(`Error saving term with ID ${term.id}:`, error);
                message.error(`Error saving term with ID ${term.id}`);
                return;
            }
        }
        message.success('All changes saved successfully');
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            render: (_, record) => (
                <Input
                    value={record.title}
                    onChange={event => {
                        const newTitle = event.target.value;
                        dispatch({ type: 'SET_TERMS', payload: state.terms.map(term => term.id === record.id ? { ...term, title: newTitle } : term) });
                    }}
                />
            ),
        },
        {
            title: 'Content',
            dataIndex: 'content',
            render: (_, record) => (
                <Input.TextArea
                    value={record.content}
                    onChange={event => {
                        const newContent = event.target.value;
                        dispatch({ type: 'SET_TERMS', payload: state.terms.map(term => term.id === record.id ? { ...term, content: newContent } : term) });
                    }}
                />
            ),
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (_, record) => (
                <Button onClick={() => handleDelete(record.id)}>Delete</Button>
            ),
        },
    ];

    return (
        <div>
            <Space style={{marginBottom: 16}}>
                <Button onClick={handleAdd}>Add</Button>
                <Button onClick={handleSaveAll}>Save All</Button>
            </Space>
            <Table dataSource={state.terms} rowKey="id" columns={columns}/>
        </div>
    );
}
