import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Col, Row } from 'antd';

import AccountInfo from "../component/account/detail/AccountInfo";
import AccountInventory from "../component/account/detail/AccountInventory";

import axios from 'axios';
import qs from 'qs';
import AccountPointDetail from "../component/account/detail/AccountPointDetail";

export default function AccountDetail() {
    const [detailData, setDetailData] = useState();
    const [inventoryData, setInventoryData] = useState();
    const [statusList, setStatusList] = useState();
    const [totalElements, setTotalElements] = useState(0);
    const [selectedStatuses, setSelectedStatuses] = useState(['ORDERED', 'BOX_OPENED']);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const getAccountDetail = async (id) => {
        try {
          const { data } = await axios.get(`/accounts/${id}`);
          setDetailData(data);
        } catch (error) {
          console.error(error.message);
        }
    };

    const getAccountInventory = async (inventoryPage, ownerId, selectedStatuses, startDate, endDate) => {
        try {
            const params = {
                ownerId: ownerId,
                page: inventoryPage-1,
                size: 20,
                statuses: selectedStatuses,
                sort: 'openTime,desc'
            };

            if (startDate) {
                params.startDate = startDate;
            }
            if (endDate) {
                params.endDate = endDate;
            }


            const { data } = await axios.get(`/admin/order-items`, {
                params: params,
                paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: 'repeat' })
                }
            });

            setInventoryData(data.content);
            setTotalElements(data.totalElements);
        } catch (error) {
            console.error(error.message);
        }
    };

    const getStatusList = async () => {
        const { data } = await axios.get(`/enums`, {
            params: {
                enumType: 'OrderItemStatus'
            }
        });
        setStatusList(data);
    }



    useEffect(() => {
        getAccountDetail(searchParams.get('id'));
        getAccountInventory(1, searchParams.get('id'));
        getStatusList();
    }, []);
    useEffect(() => {
        getAccountInventory(1, searchParams.get('id'));
    }, [selectedStatuses]);

    return (
        <>
            <Row gutter={16} style={{ marginBottom: 20 }}>
                <Col span={12}>
                    <Row>
                        <Col span={24}>
                           { detailData && <AccountInfo detail={detailData} getAccountDetail={getAccountDetail} /> }
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            { detailData && <AccountPointDetail detail={detailData} getAccountDetail={getAccountDetail} /> }
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>

                    { detailData &&
                        <AccountInventory
                            user={detailData}
                            getAccountDetail={getAccountDetail}
                            detail={inventoryData}
                            statusList={statusList}
                            totalElements={totalElements}
                            getAccountInventory={getAccountInventory}
                            selectedStatuses={selectedStatuses}
                            setSelectedStatuses={setSelectedStatuses}
                        /> }
                </Col>
            </Row>
        </>
    )
}
