import React, { useState } from 'react';
import { Card, Typography, Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const flexContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const flexItem = {
  marginRight: '10px',
  marginBottom: '20px'
};

const selectWidth = {
  ...flexItem,
  width: '30%',
};

const inputWidth = {
  ...flexItem,
  width: '65%',
};

export default function DeliveryInvoice({ selectedRows, selectedRowKeys, updateInvoice }) {
  const [deliveryCompany, setDeliveryCompany] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");

  const { t } = useTranslation();

  const onSave = () => {
    if (deliveryCompany == "") {
      alert(t('common.message.select-delivery-company'));
      return;
    }

    if (invoiceNumber == "") {
      alert(t('common.message.enter-invoice-number'));
      return;
    }

    if (!selectedRows || selectedRowKeys.length <= 0) {
      alert(t('common.message.select-product'));
      return;
    }

    const successEvt = async () => {
      setDeliveryCompany("");
      setInvoiceNumber("");
    };
  
    const updatedRows = selectedRows.map((item) => ({
      id: item.id,
      deliveryId: item.delivery,
      deliveryCompany,
      invoiceNumber,
    }));
  
    updateInvoice(updatedRows, successEvt);
  }

  const handleCompanyChange = (event) => {
    const _value = event.target.value;
    setDeliveryCompany(_value);
  };

  const handleInvoiceChange = (event) => {
    const _value = event.target.value;
    setInvoiceNumber(_value);
  };

  return (
    <Card title={ `${ t('deliveries.detail.invoice-number') } ${ t('common.input') }` }>
      <div style={flexContainer}>
        <div style={selectWidth}>
          <Text type="secondary">{ t('deliveries.detail.delivery-company') }</Text>
          <Input value={deliveryCompany} placeholder={t('common.message.select-delivery-company')} onChange={handleCompanyChange} />
        </div>
        <div style={inputWidth}>
          <Text type="secondary">{ t('deliveries.detail.invoice-number') } { t('common.input') }</Text>
          <Input value={invoiceNumber} placeholder={t('common.message.enter-invoice-number')} onChange={handleInvoiceChange} />
        </div>
      </div>
      <Button type="primary" onClick={onSave}>{ t('deliveries.detail.invoice-number') } { t('common.save') }</Button>
    </Card>
  );
};