import { Typography } from 'antd';
import React, { useState, useEffect } from 'react';

import BannerList from '../../component/setting/banner/list/BannerList';

import axios from 'axios';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

export default function PopupBanner() {
    const [dataSource, setDataSource] = useState();
    const [showSize, setShowSize] = useState(20);
    const [totalPage, setTotalPage] = useState();

    const { t } = useTranslation();

    useEffect(() => {
        getBannerList(1, showSize);
    }, []);

    const getBannerList = async (page, size) => {
        try {
            const { data } = await axios.get('/banners', {
                params: {
                    category: 'POPUP'
                }
            });

            const bannerOrigin = data.map((data) => {
                return { ...data, key: data.id };
            });

            setTotalPage(data.totalElements);
            setDataSource(bannerOrigin);
        } catch (error) {
            console.error(error.message);
        }
    };

    return (
        <>
            <Title level={2}>{ t("header.setting.children.popup-banner") }</Title>
            <BannerList getBannerList={getBannerList}
                    category={"POPUP"}
                    dataSource={dataSource}
                    setDataSource={setDataSource}
                    totalPage={totalPage}
                    setTotalPage={setTotalPage}
                    showSize={showSize}
                    setShowSize={setShowSize}
            />
        </>
    )
}
