import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import RangePicker from '../../common/RangePicker';

import { Col, Row, Radio, Input, Space } from 'antd';

const { Search } = Input;

export default function DeliverySearch({ setDatePeriod, searchDelivery, setDeliveryStatus }) {
    const [listType, setListType] = useState(false);
    const { t } = useTranslation();

    const filterList = async (type) => {
        setListType(type);
    };

    const onRangeChange = (dates, dateStrings) => {
        if (dates) {
            setDatePeriod(dateStrings);
        } else {
            setDatePeriod([]);
        }
    };

    return (
        <>
            <Row style={{ marginBottom: 10 }}>
                <Col span={24} style={{ marginBottom: 20 }}>
                    <Radio.Group
                        value={listType || 'all'}
                        defaultValue="all"
                        style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
                        onChange={(e) => filterList(e.target.value)}
                    >
                        <Radio.Button
                            value="all"
                            className="primary-color"
                            style={{ flex: 1, textAlign: 'center' }}
                            onClick={() => setDeliveryStatus('')}
                        >
                            {t('common.all')}
                        </Radio.Button>
                        <Radio.Button
                            value="preparing-for-shipment"
                            style={{ flex: 1, textAlign: 'center' }}
                            onClick={() => setDeliveryStatus('PREPARING_FOR_SHIPMENT')}
                        >
                            {t('deliveries.preparing-for-shipment')}
                        </Radio.Button>
                        <Radio.Button
                            value="ready-for-shipping"
                            style={{ flex: 1, textAlign: 'center' }}
                            onClick={() => setDeliveryStatus('READY_FOR_SHIPPING')}
                        >
                            {t('deliveries.ready-for-shipping')}
                        </Radio.Button>
                        <Radio.Button
                            value="shipping"
                            style={{ flex: 1, textAlign: 'center' }}
                            onClick={() => setDeliveryStatus('SHIPPING')}
                        >
                            {t('deliveries.shipping')}
                        </Radio.Button>
                        <Radio.Button
                            value="delivered"
                            style={{ flex: 1, textAlign: 'center' }}
                            onClick={() => setDeliveryStatus('DELIVERED')}
                        >
                            {t('deliveries.delivered')}
                        </Radio.Button>
                        <Radio.Button
                            value="canceled"
                            style={{ flex: 1, textAlign: 'center' }}
                            onClick={() => setDeliveryStatus('CANCELED')}
                        >
                            {t('deliveries.canceled')}
                        </Radio.Button>
                        <Radio.Button
                            value="delayed"
                            style={{ flex: 1, textAlign: 'center' }}
                            onClick={() => setDeliveryStatus('DELIVERED')}
                        >
                            {t('deliveries.delayed')}
                        </Radio.Button>
                        <Radio.Button
                            value="partially-shipped"
                            style={{ flex: 1, textAlign: 'center' }}
                            onClick={() => setDeliveryStatus('PARTIALLY_SHIPPED')}
                        >
                            {t('deliveries.partially-shipped')}
                        </Radio.Button>
                    </Radio.Group>
                </Col>
                <Col span={24} style={{ width: "100%" }}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Space.Compact style={{ width: "100%" }}>
                            <RangePicker onRangeChange={onRangeChange} />
                            <Search placeholder={`${ t('common.nickname') } ${ t('common.search') }`}
                                    onSearch={searchDelivery}
                            />
                        </Space.Compact>
                    </Space>
                </Col>
            </Row>
        </>
    )
}