import { Button, Table } from 'antd';
import React from 'react';

import Editable from './Editable';
import Columns from './Columns';

import axios from 'axios';

import './product.css';
import {useTranslation} from "react-i18next";

export default function ProductList({ rowSelection, dataSource, setDataSource, isComplete, setIsComplete, tablePagination, handleTableChange }) {

  const { t } = useTranslation();
  const onChangeEvt = (e, record, target) => {
      const changeValue = dataSource.map(data => {
          if (data.key === record.key) {
              return { ...data, [target]: e.target.checked }
          } else {
              return data;
          }
      });
      setDataSource(changeValue);
  }

  const handleDelete = async (key) => {
    await axios.put(`/products/${key}/discard`, { isDiscard: true });
    setIsComplete(!isComplete);
  };

  const handleSave = async (key) => {
    const saveData = dataSource.find((item) => item.key === key);
    try {
      if (key === 0) {
        await axios.post(`/products`, saveData);
      } else {
        await axios.patch(`/products/${key}`, saveData);
      }
      setIsComplete(!isComplete);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveAll = async () => {
    // dataSource 배열의 모든 항목에 대해 handleSave 함수를 호출
    await Promise.all(dataSource.map(async (item) => {
      await handleSave(item.key);
    }));
    setIsComplete(!isComplete); // 상태 업데이트
  };

  const defaultColumns = Columns(handleDelete, handleSave, onChangeEvt);

  const handleAdd = () => {
    const newData = {
      key: 0,
      boxCode: "",
      productCode: "",
      productName: "",
      brand: "",
      make: "",
      offer: "",
      size: "",
      color: "",
      targetQty: 0,
      realQty: 0,
      shippingGuage: 0,
      supplier: "",
      category: "",
      subCategory: "",
      storage: "",
      purchasePrice: 0,
      discountedPrice: 0,
      discountRate: 0,
      retailPrice: 0,
      mainImageURL: "",
      subImageURL1: "",
      subImageURL2: "",
      productDetail: "",
      features: "",
      afterService: "",
      isActive: true,
      isVisible: true,
      isDiscard: false,
      seqNumber: 0,
      isGiftUnAvailable: true,
      isMobileCoupon: true,
      isHoneyCoupon: true
    };
    setDataSource([newData, ...dataSource]);
  };

  const handleStateSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const components = {
    body: {
      row: Editable.EditableRow,
      cell: Editable.EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleStateSave,
      }),
    };
  });

  return (
    <div className='table-container'>
      <Button
          onClick={handleAdd}
          type="primary"
          style={{
            marginBottom: 16,
            marginRight: 10, // Add right margin to the "Add a row" button
          }}
      >
        Add a row
      </Button>
      <Button
          onClick={handleSaveAll}
          style={{
            marginLeft: 10, // Add left margin to the "Save All" button
          }}
      >
        {t('common.save-all')}
      </Button>
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        className='product-table'
        scroll={{ x: true }}
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={tablePagination}
        onChange={handleTableChange}
      />
    </div>
  );
};
