import React, { useState, useEffect } from 'react';

import BoxList from '../../component/setting/box/list/BoxList';

import axios from 'axios';

export default function BoxSetting() {
    const [selectedRows, setSelectedRows] = useState();
    const [dataSource, setDataSource] = useState();
    const [showSize, setShowSize] = useState(20);
    const [totalPage, setTotalPage] = useState();

    useEffect(() => {
        getBoxList(1, showSize);
    }, []);

    const getBoxList = async (page, size) => {
        try {
            const { data } = await axios.get('/box-items', { 
            params: { 
                page: page-1, 
                size,
                sortBy: 'id'
            } 
            });

            const boxOrigin = data.content.map((data) => {
            return { ...data, key: data.id };
            });

            setTotalPage(data.totalElements);
            setDataSource(boxOrigin);
        } catch (error) {
            console.error(error.message);
        }
    };
    const rowSelection = {
        onChange: (keys, rows) => {
          setSelectedRows(rows);
        },
    };

    return (
        <BoxList getBoxList={getBoxList}
                rowSelection={rowSelection}
                dataSource={dataSource}
                setDataSource={setDataSource}
                totalPage={totalPage}
                setTotalPage={setTotalPage}
                showSize={showSize}
                setShowSize={setShowSize}
        />
    )
}