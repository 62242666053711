import React from 'react';
import { Card, Row, Col } from 'antd';
import CouponForm from './CouponForm';

export default function CouponUpdate() {
  return (
    <Row justify="center" align="middle">
      <Col xs={24} sm={20} md={20} lg={16} xl={16}>
        <Card title="Coupon Update">
          <CouponForm editType="update" />
        </Card>
      </Col>
    </Row>
  );
}
