import React from 'react';

import RangePicker from '../common/RangePicker';
import { useTranslation } from 'react-i18next';

import { Col, Row, Input, Space } from 'antd';

const { Search } = Input;

export default function InventorySearch({ setDatePeriod, searchOrder }) {
    const { t } = useTranslation();

    const onRangeChange = (dates, dateStrings) => {
        if (dates) {
            setDatePeriod(dateStrings);
        } else {
            setDatePeriod([]);
        }
    };

    return (
        <>
            <Row style={{ marginBottom: 10 }}>
                <Col span={24} style={{ width: "100%" }}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Space.Compact style={{ width: "100%" }}>
                            <RangePicker onRangeChange={onRangeChange} />
                            <Search placeholder={
                                `${ t('common.product') }${ t('common.name') } ${ t('common.search') }`}
                                    onSearch={searchOrder}
                            />
                        </Space.Compact>
                    </Space>
                </Col>
            </Row>
        </>
    )
}