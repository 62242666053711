import React from 'react';
import { useTranslation } from 'react-i18next';

import { addCommas, dateFormat, getLanguageKey } from '../../common/Util';

export default function Columns () {
  const { t } = useTranslation();

  const columns = [ 
    { 
      title: t('orders.sub-number'), 
      dataIndex: 'id', 
    }, 
    { 
      title: t('common.all'), 
      dataIndex: 'totalAvailable', 
    }, 
    { 
      title: t('orders.detail.random'),
      dataIndex: 'randomInt', 
    }, 
    { 
      title: t('orders.detail.box-type'), 
      dataIndex: 'boxCode', 
    }, 
    { 
      title: t('orders.detail.orderer'), 
      dataIndex: 'orderer', 
      render: (val) => <span>{ val.nickName }</span>, 
    }, 
    { 
      title: t('orders.detail.owner'),
      dataIndex: 'owner', 
      render: (val) => <span>{ val.nickName }</span>, 
    }, 
    { 
      title: t('orders.detail.product-name'),
      dataIndex: 'productName', 
    }, 
    { 
      title: t('orders.detail.payback-point'),
      dataIndex: 'changePoint', 
    }, 
    { 
      title: t('orders.detail.paid-amount'),
      dataIndex: 'paidAmount', 
      render: (val) => <span>{ addCommas(val) }</span>, 
    }, 
    { 
      title: t('orders.detail.point-used'), 
      dataIndex: 'pointUsed', 
      render: (val) => <span>{ addCommas(val) }</span>, 
    },
    // 임시 주석
    // { 
    //   title: t('orders.detail.receive-mobile'),  
    //   dataIndex: 'receiveMobile', 
    // }, 
    // { 
    //   title: t('orders.detail.tr-id'),  
    //   dataIndex: 'trId', 
    // }, 
    { 
      title: t('common.update-datetime'),
      dataIndex: 'updatedOn', 
      render: (val) => <span>{ dateFormat(val) }</span>, 
    }, 
    { 
      title: t('orders.detail.regular-price'),
      dataIndex: 'regularPrice', 
      render: (val) => <span>{ addCommas(val) }</span>, 
    }, 
    { 
      title: t('orders.detail.original-price'),
      dataIndex: 'originalPrice', 
      render: (val) => <span>{ addCommas(val) }</span>, 
    }, 
    { 
      title: t('common.status'),
      dataIndex: 'status',
      render: (val) => <span>{ t(getLanguageKey('orders.detail', val)) }</span>, 
    }, 
  ]; 
 
  return columns; 
};
