import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import axios from 'axios';

import BasicLayout from './layout/BasicLayout';

import Login from './page/Login';
import NotFound from './page/NotFound';
import Product from './page/Products';
import Order from './page/Order';
import OrderDetail from './page/OrderDetail';
import Delivery from './page/Delivery';
import DeliveryDetail from './page/DeliveryDetail';
import Account from './page/Account';
import AccountDetail from './page/AccountDetail';
import BoxSetting from './page/setting/BoxSetting';
import BoxAdd from './page/setting/BoxAdd';
import MidBanner from './page/setting/MidBanner';
import TopBanner from './page/setting/TopBanner';
import PopupBanner from './page/setting/PopupBanner';
import Inquiry from './page/Inquiry';
import InquiryDetail from './page/InquiryDetail';
import Coupon from './page/Coupon';
import Permission from './page/Permission';
import Inventory from './page/Inventory';
import IntegratedRules from './page/IntegratedRules';
import TermsOfService from './page/TermsOfService';
import FAQ from './page/FAQ';

import Cookies from 'js-cookie';
import { jwtVerify } from 'jose';

import './locales/i18n';
import CouponUpdate from './component/coupon/detail/CouponUpdate';
import CouponInsert from './component/coupon/detail/CouponInsert';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const ProtectedRoute = ({ path, element: Component }) => {
    const [authorization, setAuthorization] = useState(null);
    useEffect(() => {
      const verifyToken = async () => {
        try {
          const token = Cookies.get('Authorization');
          const key = new TextEncoder().encode(process.env.REACT_APP_JWT_KEY);
          const verifiedToken = await jwtVerify(token, key, {
            algorithms: ['HS512'] // 알고리즘 지정
          });
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
          setAuthorization(verifiedToken);
        } catch (error) {
          setAuthorization(false);
        }
      };
      verifyToken();
    }, [path]);

    if (authorization === null) {
      return null;
    }

    return (
      authorization ?
      <BasicLayout>
        <Component />
      </BasicLayout>
      :
      <Navigate to="/login" />
    );
};

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<ProtectedRoute path="/" element={Product} />} />
          <Route path="*" element={<ProtectedRoute element={NotFound} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/box-settings" element={<ProtectedRoute path="/box-settings" element={BoxSetting} />} />
          <Route path="/box-add" element={<ProtectedRoute path="/box-add" element={BoxAdd} />} />
          <Route path="/mid-banner" element={<ProtectedRoute path="/mid-banner" element={MidBanner} />} />
          <Route path="/top-banner" element={<ProtectedRoute path="/top-banner" element={TopBanner} />} />
          <Route path="/popup-banner" element={<ProtectedRoute path="/popup-banner" element={PopupBanner} />} />
          <Route path="/products" element={<ProtectedRoute path="/products" element={Product} />} />
          <Route path="/orders" element={<ProtectedRoute path="/orders" element={Order} />} />
          <Route path="/order-detail" element={<ProtectedRoute path="/order-detail" element={OrderDetail} />} />
          <Route path="/inquiry" element={<ProtectedRoute path="/order-detail" element={Inquiry} />} />
          <Route path="/inquiry-detail" element={<ProtectedRoute path="/order-detail" element={InquiryDetail} />} />
          <Route path="/delivery" element={<ProtectedRoute path="/delivery" element={Delivery} />} />
          <Route path="/delivery-detail" element={<ProtectedRoute path="/delivery-detail" element={DeliveryDetail} />} />
          <Route path="/accounts" element={<ProtectedRoute path="/accounts" element={Account} />} />
          <Route path="/account-detail" element={<ProtectedRoute path="/account-detail" element={AccountDetail} />} />
          <Route path="/coupons" element={<ProtectedRoute path="/coupons" element={Coupon} />} />
          <Route path="/coupon-create" element={<ProtectedRoute path="/coupon-create" element={CouponInsert} />} />
          <Route path="/coupon-detail" element={<ProtectedRoute path="/coupon-detail" element={CouponUpdate} />} />
          <Route path="/permission" element={<ProtectedRoute path="/permission" element={Permission} />} />
          <Route path="/inventory" element={<ProtectedRoute path="/inventory" element={Inventory} />} />
          <Route path="/integrated-rules" element={<ProtectedRoute path="/integrated-rules" element={IntegratedRules} />} />
          <Route path="/terms-of-service" element={<ProtectedRoute path="/terms-of-service" element={TermsOfService} />} />
          <Route path="/faq" element={<ProtectedRoute path="/faq" element={FAQ} />} />
      </Routes>
  </BrowserRouter>
  );
}
