import React from 'react';
import { Table } from 'antd';

import Columns from './Columns';

export default function DeliveryItem({ rowSelection, deliveryItems }) {
  const tablePagination = {
    position: ['bottomLeft']
  };

  return (
    <div className='table-container'>
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        scroll={{ x: true }}
        bordered
        dataSource={deliveryItems}
        columns={Columns()}
        pagination={tablePagination}
      />
    </div>
  );
};