import { Table } from 'antd';
import React from 'react';

import Editable from './Editable';
import Columns from './Columns';

import axios from 'axios';
import { useTranslation } from 'react-i18next';
import BannerModal from '../BannerModal';

export default function BannerList({ category, getBannerList, dataSource, setDataSource, totalPage, showSize, setShowSize }) {
  const { t } = useTranslation();

  const onChangeEvt = (e, record, target) => {
    const changeValue = dataSource.map(data => {
        if (data.key === record.key) {
            return { ...data, [target]: e.target.checked }
        } else {
            return data;
        }
    });
    setDataSource(changeValue);
  }

  const handleDelete = async (key) => {
    console.log('개발 진행 중')
  };

  const handleSave = async (key) => {
    const saveData = dataSource.find((item) => item.key === key);
    console.log(saveData);
    await axios.put(`/banners/${saveData.id}`, saveData)
    .then(() => {
      alert(t('common.message.done'));
    })
    .catch(e => {
      console.log(e);
      alert(t('common.message.status-change-failed'));
    });
  };

  const handleStateSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const pageChange = async (page, size) => {
    await setShowSize(size);
    await getBannerList(page, size);
  }

  const defaultColumns = Columns(handleDelete, handleSave, onChangeEvt);

  const columns = defaultColumns.map((col) => {

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleStateSave
      }),
    };
  });

  const components = {
    body: {
      row: Editable.EditableRow,
      cell: Editable.EditableCell,
    },
  };

  const tablePagination = {
    pageSize: showSize,
    total: totalPage,
    onChange: pageChange,
    position: ['bottomLeft'],
    pageSizeOptions: ['10', '20', '30', '40'],
    showSizeChanger: true
  };

  return (
    <div className='table-container'>
      <div style={{
        display: "flex",
        flexDirection: "row-reverse"
      }}>
        <BannerModal category={category} getBannerList={getBannerList} showSize={showSize} />
      </div>
      <Table
        className='box-table'
        scroll={{ x: true }}
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={tablePagination}
      />
    </div>
  );
};
