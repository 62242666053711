import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row, Radio, Input } from 'antd';

const { Search } = Input;

export default function InquirySearch({ inquiryStatus, setInquiryStatus, onSearchHandler }) {
    const { t } = useTranslation();

    const filterList = async (status) => {
        setInquiryStatus(status);
    };

    return (
        <>
            <Row style={{ marginBottom: 10 }}>
                <Col span={24} style={{ marginBottom: 10 }}>
                    <Search placeholder={`${ t('common.search') }`}
                        onSearch={onSearchHandler}
                    />
                </Col>
                <Col span={24}>
                    <Radio.Group
                        value={inquiryStatus || ''}
                        defaultValue=""
                        style={{ display: 'flex', width: '100%', textAlign: 'center', marginBottom: '20px' }}
                        onChange={(e) => filterList(e.target.value)}
                    >
                        <Radio.Button
                            value=""
                            className="primary-color"
                            style={{ width: '50%' }}
                        >
                            {t('common.all')}
                        </Radio.Button>
                        <Radio.Button
                            value="PENDING"
                            style={{ width: '50%' }}
                        >
                            {t('inquiries.pending')}
                        </Radio.Button>
                        <Radio.Button
                            value="COMPLETE"
                            style={{ width: '50%' }}
                        >
                            {t('inquiries.complete')}
                        </Radio.Button>
                        <Radio.Button
                            value="REFUNDED"
                            style={{ width: '50%' }}
                        >
                            {t('inquiries.refunded')}
                        </Radio.Button>
                    </Radio.Group>
                </Col>
            </Row>
        </>
    )
}