import React, { useState, useEffect } from 'react';

import InventorySearch from '../component/inventory/InventorySearch';
import InventoryList from '../component/inventory/InventoryList';

import axios from 'axios';
import moment from 'moment';
import qs from 'qs';
import { useTranslation } from 'react-i18next';

export default function Order() {
    const [selectedRows, setSelectedRows] = useState();
    const [dataSource, setDataSource] = useState();
    const [datePeriod, setDatePeriod] = useState([]);
    const [searchName, setSearchName] = useState();
    const [searching, setSearching] = useState(false);
    const [tablePagination, setTablePagination] = useState({
      pageSize: 20,
      total: 0,
      onChange: async (page, size) => {
        setTablePagination(current => ({
          ...current,
          current: page,
          pageSize: size
        }))
      },
      current: 1,
      position: ['bottomLeft'],
      pageSizeOptions: ['10', '20', '30', '40'],
      showSizeChanger: true
    })

    const { t } = useTranslation();

    const getInventoryList = async (page, size) => {
      try {
        const params = {
            page: page-1, 
            size: size ? size : tablePagination.pageSize,
            statuses: ['BOX_OPENED'],
            startDate: datePeriod[0]? moment(datePeriod[0]).format("YYYY-MM-DDTHH:mm:ss") : null,
            endDate: datePeriod[1]? moment(datePeriod[1]).format("YYYY-MM-DDTHH:mm:ss") : null,
            productName: searchName ? searchName : null
          };

          Object.keys(params).forEach(key => params[key] === null && delete params[key]);

          const { data } = await axios.get(`/admin/order-items`, { 
            params: params,
            paramsSerializer: params => {
              return qs.stringify(params, { arrayFormat: 'repeat' })
            }
          });

        const dataOrigin = data.content.map((data) => {
          return { ...data, key: data.id };
        });
  
        setTablePagination(current => ({
          ...current,
          total: data.totalElements,
          current: page
        }))
        setDataSource(dataOrigin);
      } catch (error) {
        console.error(error.message);
      }
    };

    useEffect(() => {
      getInventoryList(tablePagination.current, tablePagination.pageSize);
    }, [tablePagination.current, tablePagination.pageSize]);

    const rowSelection = {
      onChange: (keys, rows) => {
        setSelectedRows(rows);
      },
    };

    const searchOrder = async (productName) => {
        if (datePeriod.length <= 0) {
            alert(t('common.message.select-period'));
            return;
        }

        setSearchName(productName);
        setSearching(true); // Add this line to disable the search button

        try {
            await getInventoryList(1, tablePagination.pageSize);
        } finally {
            setSearching(false); // Add this line to re-enable the search button
        }
    }

    useEffect(() => {
        if (searching) {
            (async () => {
                try {
                    await getInventoryList(1, tablePagination.pageSize);
                } finally {
                    setSearching(false);
                }
            })();
        }
    }, [searching, searchName]);

    return (
        <>
            <InventorySearch selectedRows={selectedRows}
                setDatePeriod={setDatePeriod}
                searchOrder={searchOrder}
            />
            <InventoryList
                rowSelection={rowSelection}
                dataSource={dataSource}
                setDataSource={setDataSource}
                tablePagination={tablePagination}
                searching={searching}
            />
        </>
    )
}