import React from 'react';
import { Table } from 'antd';

import Columns from './Columns';

export default function OrderItem({ orderItems }) {
  const tablePagination = {
    position: ['bottomLeft']
  };

  return (
    <div className='table-container'>
      <Table
        scroll={{ x: true }}
        bordered
        dataSource={orderItems}
        columns={Columns()}
        pagination={tablePagination}
      />
    </div>
  );
};