import { Button, Card, Col, Form, Input, Row, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next';

import axios from 'axios';

const { Title } = Typography;

export default function PermissionCreate({ getAdminList }) {
  const [form] = Form.useForm();
  
  const { t } = useTranslation();

  const handleFormSubmit = async (values) => {
    await axios.post('/auth/join', {
      ...values,
      role: "ROLE_ADMIN",
      subRole: "ROLE_SUB_NONE",
      isActive: true
    })
    .then(() => {
      alert(t('common.message.done'));
      form.resetFields();
      getAdminList(1);
    });
  }

  return (
    <>
      <Card>
        <Title level={3} style={{ marginTop: 0, marginBottom: '20px' }}>
          Permission Create
        </Title>
        <Form
          name="permission-form"
          layout="inline"
          form={form}
          onFinish={handleFormSubmit}
        >
          <Row gutter={12} style={{ marginBottom: 10, width: '100%' }}>
            <Col xs={7}>
              <Form.Item
                name="nickName"
                style={{ marginBottom: 10, width: '100%' }}
              >
                <Input addonBefore={t('permission.admin-id')} type="text" />
              </Form.Item>
            </Col>
            <Col xs={7}>
              <Form.Item
                name="email"
                style={{ marginBottom: 10, width: '100%' }}
              >
                <Input addonBefore={t('permission.email')} type="text" />
              </Form.Item>
            </Col>
            <Col xs={7}>
              <Form.Item
                name="password"
                type="password"
                style={{ marginBottom: 10, width: '100%' }}
              >
                <Input addonBefore={t('permission.password')} type="password" />
              </Form.Item>
            </Col>
            <Col xs={3}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {t('common.create')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  )
}
