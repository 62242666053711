import axios from 'axios';
import { numberValidator } from '../../common/Util';
import { useTranslation } from 'react-i18next';

import { Button, Form, Input, Select, Row, Col } from 'antd';
import { useState, useEffect } from 'react';
 const { Option } = Select;

 export default function Simulation({ getSimulationData }) {
  const [boxList, setBoxList] = useState([]);
  const { t } = useTranslation();

   useEffect(() => {
    getBoxList();
   }, []);

   const getBoxList = async () => {
    try {
      const { data } = await axios.get('/box-items', {
        params: {
          pageNumber: 0,
          pageSize: 100,
          sortBy: 'id',
        },
      });
      setBoxList(data.content);
    } catch (error) {
      console.error(error.message);
    }
  };
   const handleInputChange = (key) => (e) => {
    const newNumber = parseInt(e.target.value || '0', 10);
    if (Number.isNaN(newNumber)) {
      return;
    }
  };

   const handleFormSubmit = (values) => {
    getSimulationData(values);
  };
   return (
    <Form
      name="customized_form_controls"
      layout="inline"
      onFinish={handleFormSubmit}
      initialValues={{
        numberOfItems: 0,
        boxName: 'A박스',
        payback: 3000,
      }}
      style={{
        marginBottom: 20,
      }}
    >
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="numberOfItems"
            rules={[
              {
                required: true,
                message: t('products.number-of-items-error'),
              },
              numberValidator('numberOfItems'),
            ]}
          >
            <Input
              name="numberOfItems"
              addonBefore={t('products.number-of-items')}
              type="text"
              onChange={handleInputChange('numberOfItems')}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          {boxList.length > 0 && (
            <Form.Item name="boxName">
              <Select>
                {boxList.map((box) => (
                  <Option key={box.id} value={box.boxCode}>{box.boxCode}</Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Col>
        <Col span={8}>
          <Form.Item
            name="payback"
            rules={[
              {
                required: true,
                message: t('products.payback-error'),
              },
              numberValidator('payback'),
            ]}
          >
            <Input
              addonBefore={t('products.payback')}
              type="text"
              onChange={handleInputChange('payback')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {t('common.submit')}
        </Button>
      </Form.Item>
    </Form>
  );
}