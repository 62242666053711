import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import ko from './ko.json';

import Cookies from 'js-cookie';

const language = Cookies.get('language') ? Cookies.get('language') : 'en';

const resources = {
  en: {
    translation: en,
  },
  ko: {
    translation: ko,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: language, // 기본 언어 설정
  fallbackLng: language, // fallback 언어 설정
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;