import { useEffect, useState } from 'react';
import { DatePicker, TimePicker } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';

const { RangePicker } = DatePicker;

const DateTimePicker = ({ onRangeChange, validFrom, validTo }) => {
  const [datetime, setDateTime] = useState(null);
  const [time, setTime] = useState(null);

  const handleDateChange = (date, dateString) => {
    if (date) {
      const convertedDate = dayjs(date[0]);
      if (convertedDate.isAfter(dayjs(date[1]))) {
        setDateTime([date[1], date[0]]);
      } else {
        setDateTime(date);
      }
    } else {
        setDateTime(null);
    }
    onRangeChange(date, dateString);
  };

  const handleTimeChange = (time, timeString) => {
    setTime(timeString);
  };

  useEffect(() => {
    if(validFrom && validTo) {
      setDateTime([dayjs(validFrom), dayjs(validTo)],
        [ moment(validFrom).format("YYYY-MM-DD HH:mm:ss"),
          moment(validTo).format("YYYY-MM-DD HH:mm:ss")
        ]
      );
    }
  }, [onRangeChange])

  return (
    <RangePicker
        style={{ width: '100%' }}
        showTime={{ format: 'HH:mm:ss' }}
        format="YYYY-MM-DD HH:mm:ss"
        onChange={handleDateChange}
        value={datetime}
        onOk={() => setTime(time)}
        renderExtraFooter={() => (
            <TimePicker
                style={{ width: '40%' }}
                defaultValue={time}
                format="HH:mm:ss"
                onChange={handleTimeChange}
            />
        )}
    />
  );
};

export default DateTimePicker;
