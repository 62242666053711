import React from 'react';

import HeaderComp from './HeaderComp';

import { Layout } from 'antd';
const { Content } = Layout;

export default function BasicLayout({ children }) {
  return (
    <Layout className="layout">
      <HeaderComp />
      <Content style={{ backgroundColor: "white", padding: '10px' }}>
        { children }
      </Content>
    </Layout>
  );
}
