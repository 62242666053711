import React, { useState, useEffect } from 'react';
import { Table } from 'antd';

import Columns from './Columns';

import axios from 'axios';

export default function RealStatistics() {
    const [realData, setRealData] = useState();
    const [sumObject, setSumObject] = useState();

  useEffect(() => {
    getRealData();
  }, []);

  const getRealData = async () => {
    const { data } = await axios.get(`/admin/product-statistics`);
    const dataArray = data.map((item, idx) => {
      return { ...item, key: idx };
    });

    setSumObject(
      dataArray.reduce((sum, item) => {
        sum.targetQtySum += item.targetQtySum;
        sum.totalProducts += item.totalProducts;
        return sum;
      }, {
        targetQtySum: 0,
        totalProducts: 0
      })
    );

    setRealData(dataArray);
 }

  return (
    <>
      <Table
        scroll={{ x: true }}
        bordered
        dataSource={realData}
        columns={Columns(sumObject)}
        pagination={false}
      />
    </>
  );
};