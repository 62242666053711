import React, { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import { Col, Row, Typography } from 'antd';

import axios from 'axios';

import Inquiry from "../component/inquiry/detail/Inquiry";
import Answer from "../component/inquiry/detail/Answer";

const { Title } = Typography;

export default function InquiryDetail() {
    const [inquiryData, setInquiryData] = useState();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        getInquiry(searchParams.get('id'));
    }, []);

    const getInquiry = async (id) => {
        try {
          const { data } = await axios.get(`/inquiries/${id}`);
          setInquiryData(data);
        } catch (error) {
          console.error(error.message);
        }
    };

    return (
        <>
            <Row gutter={16} style={{ marginBottom: 20 }}>
                <Col span={24}>
                    <Title level={2}>
                        { inquiryData && inquiryData.customer.nickName }
                    </Title>
                </Col>
                <Col span={12}>
                    <Inquiry inquiryData={inquiryData} />
                </Col>
                <Col span={12}>
                    { inquiryData && <Answer id={searchParams.get('id')} inquiryData={inquiryData} /> }
                </Col>
            </Row>
        </>
    )
}