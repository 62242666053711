import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row, Input } from 'antd';

const { Search } = Input;

export default function CouponSearch({ onSearchHandler }) {
    const { t } = useTranslation();

    return (
        <>
            <Row style={{ marginBottom: 10 }}>
                <Col span={24} style={{ marginBottom: 10 }}>
                    <Search placeholder={`${ t('common.search') }`}
                        onSearch={onSearchHandler}
                    />
                </Col>
            </Row>
        </>
    )
}