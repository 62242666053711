import React from 'react';

import { Popconfirm, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

export default function Columns (handleDelete, handleSave, onChangeEvt) {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('common.operation'),
      dataIndex: 'operation',
      render: (_, record) => (
        <>
          <Popconfirm title={t("common.message.agree-delete")} onConfirm={() => handleDelete(record.key)}>
            <a style={{ marginRight: 10 }}>{t("common.delete")}</a>
          </Popconfirm>
          <Popconfirm title={t("common.message.agree-save")} onConfirm={() => handleSave(record.key)}>
            <a>{t("save")}</a>
          </Popconfirm>
        </>
      ),
    },
    {
      title: t('settings.banner.image-url'),
      dataIndex: 'imageURL',
      editable: true,
    },
    {
      title: 'thImageURL',
      dataIndex: 'thImageURL',

      editable: true,
    },

    {
      title: t('settings.banner.detail-page-url'),
      dataIndex: 'detailPageURL',
      editable: true,
    },
    {
      title: 'thDetailPageURL',
      dataIndex: 'thDetailPageURL',

      editable: true,
    },

    {
      title: t('settings.banner.sort'),
      dataIndex: 'sort',
      editable: true,
    },
    {
      title: t('settings.banner.scan'),
      dataIndex: 'scan',
      render: (val, record) => <Checkbox defaultChecked={val} onChange={(e) => onChangeEvt(e, record, 'scan')} />,
    },
    {
      title: t('settings.banner.active'),
      dataIndex: 'active',
      render: (val, record) => <Checkbox defaultChecked={val} onChange={(e) => onChangeEvt(e, record, 'active')} />,
    }
  ];

  return columns;
};
