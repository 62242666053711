import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { dateFormat } from '../../common/Util';

export default function Columns() {
  const { t } = useTranslation();

  const stringSorter = (a, b, key) => {
    const valueA = a[key] || '';
    const valueB = b[key] || '';
    return valueA.localeCompare(valueB);
  };

  const dateSorter = (a, b, key) => {
    const dateA = a[key] ? new Date(a[key]) : new Date(0);
    const dateB = b[key] ? new Date(b[key]) : new Date(0);
    return dateA - dateB;
  };

  const numberSorter = (a, b, key) => {
    const valueA = a[key] !== null ? a[key] : -Infinity;
    const valueB = b[key] !== null ? b[key] : -Infinity;
    return valueA - valueB;
  };

  const columns = [
    {
      title: t('accounts.number'),
      dataIndex: 'id',
      render: (val) => <Link to={`/account-detail?id=${val}`}>{val}</Link>,
      sorter: true,
    },
    {
      title: t('common.nickname'),
      dataIndex: 'nickName',
      render: (text, record) => <Link to={`/account-detail?id=${record.id}`}>{text}</Link>,
      sorter: true,
    },
    {
      title: t('accounts.email'),
      dataIndex: 'email',
      sorter: true,
    },
    {
      title: t('accounts.phone-number'),
      dataIndex: 'phoneNumber',
      sorter: true,
    },
    {
      title: t('accounts.create-on'),
      dataIndex: 'createdOn',
      render: (val) => <span>{dateFormat(val)}</span>,
      sorter: true,
    },
    {
      title: t('accounts.last-login'),
      dataIndex: 'lastLogin',
      render: (val) => <span>{dateFormat(val)}</span>,
      sorter: true,
    },
  ];

  return columns;
};
