import React, { useState, useEffect } from 'react';

import ProductSearch from '../component/products/list/ProductSearch';
import ProductList from '../component/products/list/ProductList';
import RealStatistics from '../component/products/statistics/RealStatistics';
import Simulation from '../component/products/simulation/Simulation';

import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';
const { Panel } = Collapse;

export default function Product() {
  const [selectedRows, setSelectedRows] = useState();
  const [dataSource, setDataSource] = useState();
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [isComplete, setIsComplete] = useState(false);
  const [listType, setListType] = useState(false);
  const [tablePagination, setTablePagination] = useState({
    pageSize: 20,
    total: 0,
    current: 1,
    position: ['bottomLeft']
  });
  const [prevSorter, setPrevSorter] = useState({});

  const { t } = useTranslation();

  const getProductList = async (isDiscard = false, page = 1, size, sort = 'id,desc', keyword) => {
    try {
      const paramObj = {
        params: {
          isDiscard,
          page: page - 1,
          size,
          sort,
          query: keyword ? keyword : null
        }
      }

      const { data } = await axios.get('/products/search/any', paramObj);
      const productOrigin = data.content.map((data) => {
        return { ...data, key: data.id };
      });
      setTablePagination(current => ({
        ...current,
        total: data.totalElements,
        current: page
      }))
      setDataSource(productOrigin);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    let field = prevSorter.field;
    getProductList(listType, 1, tablePagination.pageSize,
        `${field ? field : 'id'},${prevSorter.order === 'ascend' ? 'asc' : 'desc'}`,
        searchKeyword);
  }, [isComplete]);

  const rowSelection = {
    onChange: (keys, rows) => {
      setSelectedRows(rows);
    },
  };

  const onSearchKeyword = (keyword) => {
    let field = prevSorter.field;
    setSearchKeyword(keyword);
    getProductList(listType, 1, tablePagination.pageSize,
      `${field ? field : 'id'},${prevSorter.order === 'ascend' ? 'asc' : 'desc'}`,
      keyword);
  }

  const handleTableChange = async (pagination, filters, sorter) => {
    let field = sorter.field;
    setTablePagination(current => ({
      ...current,
      current: pagination.current,
      pageSize: pagination.pageSize
    }))

    // 정렬 기준이 변경되었는지 확인
    const isSorterChanged = prevSorter.field !== sorter.field || prevSorter.order !== sorter.order;

    // 정렬 기준이 변경되었다면 페이지네이션을 첫 페이지로 설정, 그렇지 않으면 현재 페이지 사용
    const currentPage = isSorterChanged ? 1 : pagination.current;

    await getProductList(listType, currentPage, pagination.pageSize,
      `${field ? field : 'id'},${sorter.order === 'ascend' ? 'asc' : 'desc'}`,
      searchKeyword);

    // 현재 정렬 기준을 이전 정렬 기준으로 저장
    setPrevSorter(sorter);
  };

  const filterList = async (type) => {
    let keyword = searchKeyword ? searchKeyword : null;
    await getProductList(type === 'false' ? false : true, 1, tablePagination.pageSize, 'id,desc', keyword);
    setListType(type);
  };

  return (
    <>
      <Collapse style={{ marginBottom: 20 }} defaultActiveKey={['1', '2']}>
        <Panel header={t('products.real-statistics')} key="1">
          <RealStatistics />
        </Panel>
        <Panel header={t('products.simulation')} key="2">
          <Simulation />
        </Panel>
      </Collapse>
      <ProductSearch
        getProductList={getProductList}
        selectedRows={selectedRows}
        onSearchKeyword={onSearchKeyword}
        isComplete={isComplete}
        setIsComplete={setIsComplete}
        listType={listType}
        setListType={setListType}
        filterList={filterList}
      />
      <ProductList
        getProductList={getProductList}
        rowSelection={rowSelection}
        dataSource={dataSource}
        setDataSource={setDataSource}
        isComplete={isComplete}
        setIsComplete={setIsComplete}
        tablePagination={tablePagination}
        handleTableChange={handleTableChange}
      />
    </>
  );
};
